import React from 'react';

import { 
  Container, 
  Grid,
  Typography,
  makeStyles,
 } from '@material-ui/core';

import { CtaCard } from '../General/CtaCard';
import { BrochuresCard } from './BrochuresCard';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => ({
	linkRow: {
		margin: '-3rem 0 2rem', 
    width: '100%',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
			margin: '-2rem 0 2rem',
		},
	},
}));

export const BrochuresBody = ({ cta, brochureCards }) => {
	const { ctaBackground, ctaHeader, ctaText, ctaLink, _rawCtaBlockHeader } =
		cta;
  const classes = useStyles();
  const sm = useMediaQuery('(max-width: 715px)');
	return (
		<Container style={{ marginBottom: '7rem' }}>
			<CtaCard
				background={ctaBackground}
				header={_rawCtaBlockHeader}
				ctaText={ctaText}
				ctaLink={ctaLink}
			/>
      <div className={classes.linkRow}>
        <Grid
					container
					direction='row'
					justifyContent='center'
					alignItems='center'>
					<Grid
						item
						container
						direction='row'
						xs={12}
						justifyContent='center'
						alignItems='center'>
            <Typography
							variant='body1'
							color='primary'
              style={{ display: sm ? '' : 'flex' }}>
              <img src='https://documents.workwave.com/realgreen/icons/uk-flag.jpg' alt='image of UK flag' />&nbsp;
							Are you in the United Kingdom? Download the&nbsp;<a href='https://documents.workwave.com/realgreen/rg-product-brochure-uk.pdf' target='_blank'>RealGreen U.K. Brochure</a>.
						</Typography>
              
          </Grid>
        </Grid>
      </div>

			{brochureCards.map((card, index) => (
				<BrochuresCard card={card} key={index} />
			))}
		</Container>
	);
};
