import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
	Grid,
	Typography,
	Card,
	CardContent,
	makeStyles,
} from '@material-ui/core';
import PortableText from 'react-portable-text';

const useStyles = makeStyles((theme) => ({
	root: {
		color: theme.workwaveBlue,
		padding: '0px',
		borderRadius: '20px',
		minHeight: '175px',
		boxShadow: '0 10px 40px 0px rgba(0,0,0,0.15)',
		position: 'relative',
		width: '100%',
		marginBottom: '2rem',
		// [theme.breakpoints.down('md')]: {
		// 	minHeight: '400px',
		// },
		// [theme.breakpoints.down('xs')]: {
		// 	minHeight: '500px',
		// },
		transition: 'transform 0.25s',
	},
	title: {
		fontSize: '1.25rem',
		fontWeight: 600,
		textAlign: 'left',
		color: '#183B56',
		marginLeft: '.5rem',
		lineHeight: 1.3,
		[theme.breakpoints.down('xs')]: {
			marginLeft: '1rem',
		},
	},
	pos: {
		// textAlign: 'center',
		lineHeight: '28px',
		fontWeight: 500,
	},
	discount: {
		borderRadius: '10px 10px 0px 0px',
		minHeight: '60px',
		width: '250px',
		// [theme.breakpoints.down('md')]: {
		// 	maxWidth: '35%',
		// },
	},
	cardBody: {
		marginTop: '16px',
		height: '100%',
		[theme.breakpoints.down('md')]: {
			minHeight: '90px',
		},
	},
	arrow: {
		marginLeft: '.25rem',
		transition: 'transform .25s ease-in-out',
		display: 'inline-block',
	},
	// link: {
	// 	fontSize: 16,
	// 	fontWeight: 'bold',
	// 	color: theme.workwaveBlue,
	// 	textAlign: 'center',
	// 	width: '80%',
	// 	'&:hover': {
	// 		'& $arrow': { transform: 'translateX(10px)' },
	// 	},
	// },
}));

export const BrochuresCard = ({ card }) => {
	const classes = useStyles();
	const { title, header, accentColor, _rawContent } = card;
	return (
		<Grid
			item
			xs={12}
			container
			direction='column'
			justifyContent='center'
			alignItems='center'>
			<Card className={classes.root} elevation={0}>
				<Grid
					container
					alignItems='center'
					justifyContent='flex-start'
					style={{
						top: 0,
						borderTop: `16px solid ${accentColor.hexValue ?? '#002D5C'}`,
						background: accentColor.hexValue,
					}}></Grid>
				<CardContent
					style={{
						display: 'flex',
						flexDirection: 'column',
						padding: '24px',
						alignItems: 'space-evenly',
						justifyContent: 'space-between',
						height: '100%',
					}}>
					<Grid
						item
						container
						direction='row'
						justifyContent='flex-start'
						alignItems='center'>
						<Typography
							variant='h4'
							color='primary'
							style={{ fontWeight: 700 }}>
							{header}
						</Typography>
					</Grid>
					<Grid
						container
						item
						direction='row'
						justifyContent='flex-start'
						alignItems='flex-start'
						className={classes.cardBody}>
						<Typography variant='body1' color='primary'>
							<PortableText
								content={_rawContent}
								serializers={{
									link: (props) => {
										return (
											<a
												href={props.href}
												style={{
													textDecoration: 'underline',
													color: '#002D5C',
													padding: '15px 0',
												}}
												target='_blank'
												rel='noreferrer'>
												{props.children[0]}
											</a>
										);
									},
									li: ({ children }) => (
										<li style={{ margin: '5px 0' }}>{children}</li>
									),
								}}
							/>
						</Typography>
					</Grid>
				</CardContent>
			</Card>
		</Grid>
	);
};
