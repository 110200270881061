import React from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';

import { makeStyles, Container, useMediaQuery } from '@material-ui/core';

import { Hero } from '../components/General/Hero';
import { WaveUpSVG } from '../components/WaveSVGs/WaveUpSVG';
import { WaveDownSVG } from '../components/WaveSVGs/WaveDownSVG';
import { SEO } from '../components/SEO';
import { BrochuresBody } from '../components/Brochures/BrochuresBody';
import { Resources } from '../components/General/Resources';

const Form = loadable(() => import('../components/ContactForm/Form'));

const useStyles = makeStyles((theme) => ({
	heroVideoCont: {
		marginBottom: '4rem',
		[theme.breakpoints.down('md')]: {
			marginBottom: '2rem',
		},
	},

	formCont: {
		padding: '12rem 0 24rem 0',
		[theme.breakpoints.down('md')]: {
			padding: '8rem 0',
		},
		[theme.breakpoints.down('xs')]: {
			padding: '5rem 0',
		},
	},
	faqTitle: {
		color: theme.workwaveBlue,
	},
}));

const Brochures = ({ data, location }) => {
	const brochures = data.brochures.edges[0].node;
	const classes = useStyles();

	const {
		hero,
		metaDescription,
		metaTitle,
		pardotUrl,
		contactForm,
		cta,
		brochureCards,
		formBgImage,
		resourceTitle,
		resources,
		_rawResourceBody,
	} = brochures;

	return (
		<>
			<SEO title={metaTitle} description={metaDescription} />
			<Hero hero={hero} brochures />
			<BrochuresBody cta={cta} brochureCards={brochureCards} />
			{/* <WaveUpSVG height='213' width='100%' fill='#fff' /> */}
			{/*<WaveDownSVG height='213' width='100%' fill='#FFFFFF' />

			 <div>
				<WaveDownSVG height='213' width='100%' fill={'#FFFFFF'} />
			</div> */}

			<div
				className={classes.formCont}
				style={{
					backgroundImage: `url(${formBgImage?.asset?.gatsbyImageData?.images.fallback.src})`,
					backgroundSize: 'cover',
				}}>
				<Form
					// formId={marketoId}
					pardotUrl={pardotUrl}
					contactForm={contactForm}
					privacy
					modal={false}
					location={location}
				/>
			</div>
			{/* <WaveUpSVG height='213' width='100%' fill='#FFFFFF' /> */}
			<Container>
				<Resources
					header={resourceTitle}
					resources={resources}
					subheader={_rawResourceBody}
					noWave
				/>
			</Container>
			<div>
				<WaveUpSVG height='213' width='100%' fill='#f4f8ff' />
			</div>
		</>
	);
};

export const query = graphql`
	query BrochuresQuery {
		brochures: allSanityBrochures {
			edges {
				node {
					title
					metaTitle
					metaDescription
					hero {
						_rawContent
						backgroundImage {
							asset {
								gatsbyImageData(placeholder: BLURRED, height: 100)
							}
						}
						mobileBackgroundImage {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						ctaButtonText
						ctaButtonLink
						internalLink
					}
					heroImage {
						asset {
							gatsbyImageData(placeholder: BLURRED)
						}
					}
					heroVideo
					heroAlignCenter
					cta {
						ctaHeader
						ctaSubheader
						ctaText
						ctaBackground {
							asset {
								gatsbyImageData
							}
						}
						ctaLink
						_rawCtaBlockHeader
					}
					brochureCards {
						title
						header
						accentColor {
							hexValue
						}
						_rawContent
					}
					resourceTitle
					_rawResourceBody
					resources {
						title
						blurb
						image {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						ctaText
						ctaLink
					}
					contactForm {
						header
						privacyPolicy
					}
					pardotUrl
					formBgImage {
						asset {
							gatsbyImageData
						}
					}
				}
			}
		}
	}
`;

export default Brochures;
